import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { LinkContainer } from 'react-router-bootstrap';
import DesafioAllIn from '../images/desafio-all-in.png';
//import Carousel from 'react-bootstrap/Carousel';


class Home extends React.Component{
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    componentDidMount(){
        const postNumber = "48308";
        const src = "https://telegram.org/js/telegram-widget.js?21";
        const widthPost = "5%";
        const widthDiscussion = "70%";
        const scriptDiscussion = document.createElement("script");
        const scriptPosts = document.createElement("script");
        scriptDiscussion.src = src;
        scriptDiscussion.async = true;
        scriptPosts.src = src;
        scriptPosts.async = true;
        const attDiscussion = document.createAttribute("data-telegram-discussion");
        const attPosts = document.createAttribute("data-telegram-post");
        const attWidthPost = document.createAttribute("data-width");
        const attWidthDiscussion = document.createAttribute("data-width");
        attDiscussion.value = "serrafreetips/"+postNumber;
        attPosts.value = "serrafreetips/"+postNumber;
        attWidthPost.value = widthPost;
        attWidthDiscussion.value = widthDiscussion;
        const attComments = document.createAttribute("data-comments-limit");
        attComments.value = 3;
        scriptPosts.setAttributeNode(attPosts);
        scriptPosts.setAttributeNode(attWidthPost);
        console.log(scriptPosts);
        scriptDiscussion.setAttributeNode(attDiscussion);
        scriptDiscussion.setAttributeNode(attComments);
        scriptDiscussion.setAttributeNode(attWidthDiscussion);
        
        this.myRef.appendChild(scriptPosts);
        //this.myRef.appendChild(scriptDiscussion);

    }

    render(){
        return(<>
               {/* <Carousel >
                    { this.props.item.map((analise,index) => {
                        return(
                            
                                <Carousel.Item 
                                    interval={2000}
                                    className="text-center"
                                    >
                                    <LinkContainer 
                                                to={"/analises-detalhadas/"+analise.equipaA+"-"+analise.equipaB} 
                                                style={{cursor: 'pointer' }}>
                                        <img
                                        className="img-fluid    "
                                        src={require(`../images/analises/${analise.img}.png`)}
                                        alt="First slide"
                                        />
                                    </LinkContainer>
                                    <LinkContainer 
                                                to={"/analises-detalhadas/"+analise.equipaA+"-"+analise.equipaB} 
                                                style={{cursor: 'pointer' }}>
                                    <Carousel.Caption>
                                    <h3>{analise.equipaA} vs {analise.equipaB}</h3>
                                    <p style={{fontSize: 18}}>{analise.intro}</p>
                                    </Carousel.Caption>
                                    </LinkContainer>
                                </Carousel.Item>
                            
                        )
                    })}
                </Carousel> */}
                <Card 
                    text="dark" 
                    className="my-2"
                    border="white" 
                    >
                    <Row>
                        <Col xs={12} md={6} className="my-2 text-center">
                        <LinkContainer to="/desafio-pre-live-all-in" style={{cursor: 'pointer' }}><img src={DesafioAllIn} className="img-fluid" alt="Desafio All In"/></LinkContainer>
                        </Col>
                        <Col xs={12} md={6} className="my-2 text-center" >
                            <div 
                                ref={DOMNodeRef => {
                                this.myRef = DOMNodeRef;
                                }}
                            >
                            </div>
                        </Col> 
                    </Row>
                </Card>
            </>
        );
    }
    
};

export default Home;