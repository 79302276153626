export const analiseJogos = [
    {
        equipaA: 'braga1',
        equipaB: 'espanha',
        intro: 'Jogo escaldante da jornada X Jogo escaldante da jornada X Jogo escaldante da jornada X Jogo escaldante da jornada X Jogo escaldante da jornada X Jogo escaldante da jornada X',
        analise: 'Este jogo foi bastante equilibrado',
        img: 'analise-1'
    },
    {
        equipaA: 'Sporting de Braga',
        equipaB: 'Futebol Clube porto',
        intro: 'Jogo escaldante da jornada X, braga vai dar uma abada ao porto',
        analise: 'Este jogo foi bastante equilibrado, Braga com muita posso de bola e Espanha sem saber o que fazer, deu a sensacao que o resultado pecou por ser escasso\nEste jogo foi bastante equilibrado, Braga com muita posso de bola e Espanha sem saber o que fazer, deu a sensacao que o resultado pecou por ser escasso\nEste jogo foi bastante equilibrado, Braga com muita posso de bola e Espanha sem saber o que fazer, deu a sensacao que o resultado pecou por ser escasso',
        img: 'analise-2'
    },
    {
        equipaA: 'Equipa5',
        equipaB: 'Equipa6',
        intro: 'Jogo escaldante da jornada X',
        analise: 'Este jogo foi bastante equilibrado',
        img: 'analise-3'
    },
    {
        equipaA: 'Equipa7',
        equipaB: 'Equipa8',
        intro: 'Jogo escaldante da jornada X',
        analise: 'Este jogo foi bastante equilibrado',
        img: 'analise-4'
    }

];