import '../App.scss';
import { Routes, Route} from 'react-router-dom';
import Layout from './Layout';
import Home from '../routes/Home';
import EspecialEDesafio from '../routes/EspecialEDesafio';
import Prognosticos from '../routes/Prognosticos';
//import Analises from '../routes/Analises';
//import PaginasComTexto from '../routes/PaginasComTexto';
import {menuItems} from '../menuItems';
import {codigos} from '../files/codigos';
import {analiseJogos} from '../files/analiseJogos';
//import {analiseEstatisticaMensal} from '../files/analiseEstatisticaMensal';
//import { paginasComTexto } from '../files/paginasComTexto';

const App = () => {

  return (
          <div >
          <Routes>
            <Route path="/" element={<Layout />} >
              <Route index element={<Home item={analiseJogos}/>}/>
             <Route path={menuItems[0].url} element={<Home item={analiseJogos}/>}/>
              {menuItems[1].submenu.map((menu,index) =>{
                return(<>
                  <Route path={menuItems[1].url+menu.url} element={<Prognosticos item={menu}/>}/>
                  </>
                )
              })}
              {/*menuItems[2].submenu.map((menu,index) =>{
                return(<>
                  <Route path={menuItems[2].url+menu.url} element={<Analises item={analiseJogos[index]} type="games"/>}/>
                  </>
                )
              })*/}
              <Route path={menuItems[2].url} element={<EspecialEDesafio item={codigos.ApostaEspecial}/>}/>
              <Route path={menuItems[3].url} element={<EspecialEDesafio item={codigos.DesafioPreLive}/>}/>
              {/*{menuItems[5].submenu.map((menu,index) =>{
                return(<>
                  <Route path={menuItems[5].url+menu.url} element={<Analises item={analiseEstatisticaMensal[index] } type="stats"/>}/>
                  </>
                )
              })}
              {menuItems[6].submenu.map((menu,index) =>{
                return(<>
                  <Route path={menuItems[6].url+menu.url} element={<PaginasComTexto item={paginasComTexto[index+1]}/>}/>
                  </>
                )
              })}
            <Route path={menuItems[7].url} element={<PaginasComTexto item={paginasComTexto[0]}/>}/>*/}
              <Route path="*" element={<p>Not found!</p>} />
            </Route>
          </Routes>
        </div>
        );
};
export default App;
