import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const EspecialEDesafio = ({item}) =>{
    return(
        <>
        <Card 
            text="dark" 
            className="my-2"
            border="white"
            >
           { /* <Card.Header><h1 className="my-2 text-center">{item.alt}</h1></Card.Header> */}
            <Row>
            {item.map((obj,index) => {
                return(
                    <Col xs={12} md={12/item.length} className="my-2 text-center">
                        <img src={require(`../images/${obj.img}.png`)} className="img-fluid" alt={obj.alt}/>
                    </Col>
                )
            })}
            </Row>
            {/*<Row>
                {item.map((obj,index) => {
                    return(
                        <Col xs={12/item.length} className="my-2 text-center">
                            {obj.codigo}
                        </Col>
                    )
                })}
            </Row>*/}
        </Card>


            
        </>
    )

}

export default EspecialEDesafio;