//import Container  from 'react-bootstrap/Container';
import Navbar  from 'react-bootstrap/Navbar';
import Nav  from 'react-bootstrap/Nav';
import MenuItems from './MenuItems';
import {menuItems} from '../menuItems';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from '../images/logo.png'

const Header = () =>{
    return (
        <Navbar className="" border="light" collapseOnSelect style={{backgroundColor: "#FFFFFF "}} expand='lg'>
                <LinkContainer to="pagina-inicial" style={{fontFamily: "Serif", fontSize: 18}}>
                    <Navbar.Brand 
                    className="text-white ms-4" 
                    href="pagina-inicial">
                        <img
                            src={Logo}
                            width="130"
                            height="130"
                            className="d-inline-block align-top"
                            alt="Serra Free Tips logo"
                            />
                        </Navbar.Brand>
                    </LinkContainer>
                <Navbar.Toggle className="mx-2" aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-center">
                        {menuItems.map((menu,index) => {
                            return (<MenuItems items={menu} key={index}></MenuItems>)
                        })}
                </Nav>
                </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;