import NavDropdown  from 'react-bootstrap/NavDropdown';
import Nav  from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { useState } from 'react';
//import { Link } from 'react-router-dom';

const MenuItems = ({ items }) => {
    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    return (
        <>
        { items.submenu ?  (
            <>
                <NavDropdown  
                    title={
                        <span style={{fontSize: 18}} className="ms-4 text-dark ">{items.title}</span>
                        } 
                    id="basic-nav-dropdown"
                    show={show}
                    onMouseEnter={showDropdown} 
                    onMouseLeave={hideDropdown}>
                    { items.submenu.map((menu,index) => {
                        return (
                            <LinkContainer style={{fontSize: 18}} to={items.url+menu.url}><NavDropdown.Item href={`${items.url+menu.url}`} key={index}>{menu.title}</NavDropdown.Item></LinkContainer>
                        );
                    })}
                </NavDropdown>
            </>
        ): ( 
            <LinkContainer style={{fontSize: 18}} to={items.url}><Nav.Link  className="ms-4 text-dark" href={`${items.url}`}>{items.title}</Nav.Link></LinkContainer>
        )}</>
    );
};

export default MenuItems;