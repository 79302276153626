import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import {menuItems} from '../menuItems';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

const Footer = () => {
    return (<>
                <Row  className="justify-content-md-center">
                  {menuItems[1].submenu.map((menu,index) =>{
                  return(<>
                  <Col xs={6} md={3} className="mt-2 text-center">
                        <LinkContainer to={menuItems[1].url+menu.url} style={{ cursor: 'pointer' }}><img src={require(`../images/${menu.url.replace('/','')}-banner.png`)} className="img-fluid" alt={menu.title}/></LinkContainer>
                    </Col>
                    </>
                  )
                })}
            </Row>
            <Row className="my-2 justify-content-md-center">
              <CardGroup>
                <Card border="white">
                  <a href="https://btt-pt.maawdsgf.com/pt/sportsbook?partner=p4453p19447pc9a4#registration-bonus" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                    <img src={require("../images/banner-1.png")} className="img-fluid" alt="Bettilt"/>
                  </a>
                </Card>
                <Card border="white">
                <a href="https://ad.22betpartners.com/redirect.aspx?pid=3127&bid=1517&lpid=74"  style={{ cursor: 'pointer' }}>
                  <img src={require("../images/banner-2.png")} className="img-fluid" alt="22Bet"/>
                </a>  
                  </Card>
                </CardGroup>
            </Row>
            <Row >
              <Col xs={4} className="text-center">
                <a href="https://www.facebook.com/groups/884758438327770/?ref=share_group_link" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                  <img src={require("../images/facebook.png")}  alt="facebook"/>
                </a>  
              </Col>
              <Col xs={4} className="text-center">
                <a href="https://www.instagram.com/serrafreetipsoficial13/" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                  <img src={require("../images/instagram.png")}  alt="instagram"/>
                </a>  
              </Col>
              <Col xs={4} className="text-center">
                <a href="https://t.me/serrafreetips" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                  <img src={require("../images/telegram.png")}  alt="telegram"/>
                </a>  
              </Col>
            </Row>
            <Row>
              <Col className="text-center my-4" >
                <span style={{fontSize: 25}}> Contacte-nos via email: <a href = "mailto: Serrafreetips@gmail.com">Serrafreetips@gmail.com</a></span>
              </Col>
            </Row>
                

    </>)
}

export default Footer;