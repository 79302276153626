import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './Header';
/*import Anuncio from './Anuncio';*/
import Footer from './Footer';

const Layout = () =>{
    return (
        <Container fluid="md" >
        <div>
            <Row>
            <Header/>
            </Row>
            <Row >
                <Col  xs={12}>
                    {/* 
                    <Anuncio/>
                    */}
                    <Outlet/>
                    {/* 
                    <Anuncio/>
                    */}
                    <Footer/>
                </Col>
            </Row>
        </div>
        </Container>
    );
};

export default Layout;