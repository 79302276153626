import Card from 'react-bootstrap/Card'

const Prognosticos = ({item}) =>{
    return (
        <>
        <Card 
            text="dark" 
            className="my-2"
            border="white"
            >
                <Card.Body className="text-center">
                    <img src={require(`../images/prognosticos/${item.url.replace('/','')}.png`)} className="img-fluid" alt={item.title}/>
                </Card.Body> 
        </Card>
        </>
    )
}
export default Prognosticos;