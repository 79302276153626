export const codigos = {
    ApostaEspecial: [
        {
            codigo: 'serra',
            img: 'especial-1',
            alt: 'Aposta especial do dia 1' 
        }
    ],
    DesafioPreLive: [
        {
            codigo: 'serra',
            img: 'pre-live-1',
            alt: 'Aposta especial do dia 1' 
        }/*,
        {
            codigo: 'ze',
            img: 'pre-live-2',
            alt: 'Aposta especial do dia 2' 
        }*/
    ]
}

    