//import { analiseJogos } from './files/analiseJogos';

export const menuItems = [
    {
        title: 'Página inicial',
        url: '/pagina-inicial'
    },
    {
        title: 'Prognósticos',
        url: '/prognosticos',
        submenu : [
            {
                title: 'Dobradinha',
                url: '/dobradinha'
            },
            {
                title: 'Dobradinha de Hockey',
                url: '/dobradinha-de-hockey'
            },
            {
                title: 'Combinada',
                url: '/combinada'
            },
            {
                title: 'Tiro do Dia',
                url: '/tiro-do-dia'
            }
        ],
    },
    /*{
        title: 'Análises detalhadas',
        url: '/analises-detalhadas',
        submenu : [
            {
                title: analiseJogos[0].equipaA+' - '+analiseJogos[0].equipaB,
                url: '/'+analiseJogos[0].equipaA.toLowerCase()+'-'+analiseJogos[0].equipaB.toLowerCase()
            },
            {
                title: analiseJogos[1].equipaA+' - '+analiseJogos[1].equipaB,
                url: '/'+analiseJogos[1].equipaA.toLowerCase()+'-'+analiseJogos[1].equipaB.toLowerCase()
            },
            {
                title: analiseJogos[2].equipaA+' - '+analiseJogos[2].equipaB,
                url: '/'+analiseJogos[2].equipaA.toLowerCase()+'-'+analiseJogos[2].equipaB.toLowerCase()
            },
            {
                title: analiseJogos[3].equipaA+' - '+analiseJogos[3].equipaB,
                url: '/'+analiseJogos[3].equipaA.toLowerCase()+'-'+analiseJogos[3].equipaB.toLowerCase()
            }
        ],
    },*/
    {
        title: 'Aposta Especial dia',
        url: '/aposta-especial-dia'
    },
    {
        title: 'Desafio Pré-Live All-In',
        url: '/desafio-pre-live-all-in'
    }/*,
    {
        title: 'Análise Mensal',
        url: '/analise-mensal',
        submenu : [
            {
                title: 'Telegram - Lives',
                url: '/telegram-lives'
            },
            {
                title: 'Análises detalhadas',
                url: '/analises-detalhadas'
            },
            {
                title: 'Prognósticos',
                url: '/prognosticos'
            }, 
        ],
    },
    {
        title: 'Glossário',
        url: '/glossario-apostas-desportivas',
        submenu : [
            {
                title: 'Mercados',
                url: '/mercados'
            },
            {
                title: 'Termos Técnicos Casa de Apostas',
                url: '/termos-tecnicos-casa-de-apostas'
            },
        ],
    },
    {
        title: 'Passatempo',
        url: '/passatempo'
    }*/
];